import {BaseModel} from "@/models/base";
import {requests} from "@/_helpers/requests";
import {Address} from "@/models/address";
import {Deal} from "@/models/deal";

export class Cafe extends BaseModel {
    static defaultRoute = 'cafes';

    constructor(id, identifier, name, type, openTimes, address, deal) {
        super();

        this.id = id;
        this.identifier = identifier;
        this.name = name;
        this.type = type;
        this.openTimes = openTimes;
        this.address = address;
        this.deal = deal;

        this.openStatus = 'closed';
        this.distance = "-";
    }

    getOpenTimesToday() {
        const day = (new Date()).getDay();
        if (this.openTimes == null || this.openTimes[day] == null) {
            return [];
        }
        return this.openTimes[day];
    }

    updateOpenStatus() {
        const times = this.getOpenTimesToday();
        if (times.length === 0)
            this.openStatus = 'closed';

        const now = new Date();
        const nowMinutes = now.getHours() * 60 + now.getMinutes();
        let status = 'closed';

        times.forEach((time) => {
            if (time[0] < nowMinutes && time[1] > nowMinutes) {
                status = (time[1] - nowMinutes < 30)?'almost-closed':'open';
            }
        });

        //Only update variable on change
        if (this.openStatus !== status) {
            this.openStatus = status;
        }
    }

    /**
     * Returns the cafes by given cityId
     * @param cityId
     */
    static loadForCityID(cityId) {
        return new Promise((resolve, reject) => {
            requests.get(`cities/${cityId}/walk-talk-deals`).then((response) => {
                resolve(this.fromJsonList(response.data));
            }, error => {
                reject(error);
            })
        });
    }

    /**
     * Turns json into this model
     * @param json
     * @returns {*}
     */
    static fromJSON(json) {
        const cafe = new Cafe(json.id, json.identifier, json.locationName, json.locationType, json.openTimes,
            new Address(json.lat, json.lon, json.street, json.postalCode,
                json.photo),
            new Deal(json.dealName, json.oldPrice, json.newPrice, json.dealPhoto));
        cafe.updateOpenStatus();
        return cafe;
    }
}

