var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{'lat': _vm.centerLat, 'lng': _vm.centerLon},"zoom":_vm.zoom,"map-type-id":"terrain","options":{
         zoomControl: true,
         mapTypeControl: false,
         scaleControl: false,
         streetViewControl: false,
         rotateControl: false,
         fullscreenControl: true,
         disableDefaultUi: true
       }}},[(this.currentLocation.lat && this.currentLocation.lng)?_c('GmapMarker',{attrs:{"position":{'lat': this.currentLocation.lat, 'lng': this.currentLocation.lng},"clickable":true,"icon":"http://maps.google.com/mapfiles/kml/pal3/icon32.png"}}):_vm._e(),_c('GmapMarker',{attrs:{"position":{'lat': _vm.startLat, 'lng': _vm.startLon},"clickable":true,"icon":"/start-here.png"}}),_vm._l((_vm.cafes),function(cafe,index){return _c('GmapMarker',{key:index,attrs:{"position":{'lat': cafe.address.lat, 'lng': cafe.address.lon},"clickable":true,"icon":_vm.getColor(cafe)},on:{"click":function($event){return _vm.open(cafe)}}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }