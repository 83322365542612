import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OldDate from '../views/OldDate.vue'
import TestDate from '../views/TestDate.vue'
import Date from '../views/Date.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:locationId/type/:type',
    name: 'Date',
    component: Date,
  },
  //  OLD
  {
    path: '/city/:id/:route?/:startLat?/:startLon?',
    name: 'OldDate',
    component: OldDate,
  },
  {
    path: '/:locationId/:startLat?/:startLon?',
    name: 'TestDate',
    component: TestDate,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
