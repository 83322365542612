import config from 'config';
import {authHeader} from "./auth-header";

export const requests = {
    get,
    getBlob,
    post,
    put,
    deleteReq,
    upload,
    handleResponse
};


function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function getBlob(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/${url}`, requestOptions)
        .then(response => response.blob())
        .then(data => {
            return data;
        });
}

function post(url, payload) {
    return dataRequest(url, payload, 'POST');
}

function upload(url, data) {
    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: data
    };

    return fetch(`${config.apiUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function put(url, payload) {
    return dataRequest(url, payload, "PUT");
}

function deleteReq(url, payload) {
    return dataRequest(url, payload, "DELETE");
}

function dataRequest(url, payload, method) {
    const requestOptions = {
        method: method,
        headers: {...authHeader(), ...{ 'Content-Type': 'application/json'} },
        body: JSON.stringify(payload)
    };

    return fetch(`${config.apiUrl}/${url}`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //Todo Show unauthorizes snackbar
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}