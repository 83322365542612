import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueRouter from 'vue-router'
import router from './router'

Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB6-fVLJVYP6adV_9LdMUSjQCK70ZUC0tA',
    libraries: 'places,directions',
  },
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
