<template>
  <div :class="'ribbon ribbon-'+ position"><span>{{ text }}</span></div>
</template>

<script>
export default {
  name: "Ribbon",
  props: {
    text: String,
    position: String
  }
}
</script>

<style scoped lang="scss">

.ribbon {
  width: 66%;
  height: 80%;
  z-index: 1;
  overflow: hidden;
  position: absolute;

  &::before {
    position: absolute;
    content: '';
    z-index: -1;
    display: none;
    border: 5px solid #FFA08C;
  }

  &::after {
    position: absolute;
    content: '';
    z-index: -1;
    display: none;
    border: 5px solid #FFA08C;
  }

  span {
    position: absolute;
    display: block;
    width: 110px;
    padding: 1px 0;
    background-color: #FF7154;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 500 14px 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
  }
}

.ribbon-top-left {
  top: -2px;
  left: -2px;

  &::before {
    border-top-color: transparent;
    border-left-color: transparent;
    top: 0;
    right: 0;
  }

  &::after {
    border-top-color: transparent;
    border-left-color: transparent;
    bottom: 0;
    left: 0;
  }

  span {
    right: -10px;
    top: 18px;
    transform: rotate(-45deg);
  }
}

.ribbon-top-right {
  top: -10px;
  right: -10px;

  &::before {
    border-top-color: transparent;
    border-right-color: transparent;
    top: 0;
    left: 0;
  }

  &::after {
    border-top-color: transparent;
    border-right-color: transparent;
    bottom: 0;
    right: 0;
  }

  span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }
}

.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;

  &::before {
    border-bottom-color: transparent;
    border-left-color: transparent;
    bottom: 0;
    right: 0;
  }

  &::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
    top: 0;
    left: 0;
  }

  span {
    right: -25px;
    bottom: 30px;
    transform: rotate(225deg);
  }
}

.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;

  &::before {
    border-bottom-color: transparent;
    border-right-color: transparent;
    bottom: 0;
    left: 0;
  }

  &::after {
    border-bottom-color: transparent;
    border-right-color: transparent;
    top: 0;
    right: 0;
  }

  span {
    left: -25px;
    bottom: 30px;
    transform: rotate(-225deg);
  }
}

</style>
