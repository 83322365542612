<template>
  <div id="home-tab">
    <img alt="Breeze logo" src="@/assets/logo.svg">

    <div class="info">
      <h5>What to do during a Breeze date</h5>
      <span class="title"><i class="fas fa-map-marked-alt"></i></span>
      <p>
        1. Open the map below to follow a beautiful walking route through the city
      </p>
      <span class="title">Deals</span>
      <p>
        2. Get delicious take-away snacks or drinks with a discount!
      </p>
      <p class="sub-text">
        You can find the different locations on the map. Some have a discount when you show them the Breeze app.
      </p>
      <span class="title"><i class="far fa-question-circle"></i></span>
      <p>
        3. Ask each other icebreaker questions from the list in the third tab
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#home-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  width: 60%;
  margin-bottom: 30px;
}

.info {
  padding: 0 20px;

  h5 {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;

    i {
      font-size: 30px;
    }
  }

  p {
    font-size: 14px;
    padding: 0 10px;
    margin: 5px 0 25px;

    &.sub-text {
      margin-top: -25px;
      font-size: 12px;
      font-style: italic;
    }
  }
}
</style>

<script>
export default {
  name: "HomeTab",
  props: {
    hasDeals: Boolean,
  }
}
</script>