<template>
  <div>
    <GmapMap
        ref="mapRef"
        :center="{'lat': centerLat, 'lng': centerLon}"
        :zoom="zoom"
        map-type-id="terrain"
        style="width: 100%; height: 100%"
        :options="{
           zoomControl: true,
           mapTypeControl: false,
           scaleControl: false,
           streetViewControl: false,
           rotateControl: false,
           fullscreenControl: true,
           disableDefaultUi: true
         }"
    >
      <GmapMarker
          v-if="this.currentLocation.lat && this.currentLocation.lng"
          :position="{'lat': this.currentLocation.lat, 'lng': this.currentLocation.lng}"
          :clickable="true"
          icon="http://maps.google.com/mapfiles/kml/pal3/icon32.png"
      />
      <GmapMarker
          :position="{'lat': startLat, 'lng': startLon}"
          :clickable="true"
          icon="/start-here.png"
      />
      <GmapMarker
          :key="index"
          v-for="(cafe, index) in cafes"
          :position="{'lat': cafe.address.lat, 'lng': cafe.address.lon}"
          :clickable="true"
          :icon="getColor(cafe)"
          @click="open(cafe)"
      />
    </GmapMap>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: "CafeMap",
  components: {},
  computed: {
    google: gmapApi
  },
  props: {
    centerLat: Number,
    centerLon: Number,
    startLat: Number,
    startLon: Number,
    zoom: {
      type: Number,
      default: 7,
    },
    cafes: Array,
    directions: Object,
    wayPoints: Array,
  },
  data() {
    return {
      currentLocation: {}
    }
  },
  created() {
    if(navigator.geolocation) {
      setInterval(() => {
        this.updateCurrentLocation();
      }, 1000)
    }
    if (this.wayPoints < 2) {
      return;
    }
    this.$gmapApiPromiseLazy({}).then(() => {
      this.$refs.mapRef.$mapPromise.then((map) => {
        const directionsService = new this.google.maps.DirectionsService();
        const directionsRenderer = new this.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        const wayPoints = [...this.wayPoints];
        directionsService.route(
            {
              origin: wayPoints.shift(),
              destination: wayPoints.pop(),
              waypoints: wayPoints.map((point) => { return {location: point, stopover: true}; }),
              optimizeWaypoints: false,
              travelMode: this.google.maps.TravelMode.WALKING,
            },
            (response, status) => {
              if (status === "OK") {
                directionsRenderer.setOptions({
                  hideRouteList: true,
                  draggable: false,
                  suppressMarkers: true,
                  suppressPolylines: false,
                  polylineOptions: {
                    strokeColor: '#2140A1',
                    strokeOpacity: 1,
                    strokeWeight: 2
                  }
                });
                directionsRenderer.setDirections(response);
              }
            });
      });
    });
  },
  methods: {
    open(cafe) {
      this.$emit("marker-click", cafe);
    },
    goTo(cafe) {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({lat: cafe.address.lat, lng: cafe.address.lon})
      })
    },
    recenter() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({lat: this.centerLat, lng: this.centerLon})
      })
    },
    getColor(cafe) {
      if (cafe.activated) {
        return 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png';
      }

      switch (cafe.openStatus) {
        case 'open':
          return 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
        case 'almost-closed':
          return 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
        default:
          return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
      }
    },
    updateCurrentLocation() {
      navigator.geolocation.getCurrentPosition(position => {
        this.currentLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  }
}
</script>
