import {requests} from "@/_helpers/requests";

export class BaseModel {

    static defaultRoute = '';
    constructor() {
        this.id = null;
    }

    static getAll() {
        return new Promise((resolve, reject) => {
            requests.get(this.getMethod("list")).then((response) => {
                if (response.data == null) {
                    return [];
                }
                let list = response.data.map((value) => this.fromJSON(value));
                resolve(list);
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    /**
     * Returns an object for the given id
     * @param id
     * @returns {Promise<unknown>}
     */
    static getOne(id) {
        return new Promise((resolve, reject) => {
            requests.get(this.getMethod('one').replace(':id', id)).then((response) => {
                let item = this.fromJSON(response.data);
                resolve(item);
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    static getFiltered(limit, page, filters, orderings, unique) {
        let params = this.setParams(limit, page, orderings, unique);

        if (filters && filters.size > 0) {
            params.filters = {};
            filters.forEach((item, key) => {
                params.filters[key] = item;
            });
        }

        return new Promise((resolve, reject) => {
            let method = this.getMethod("filtered");
            if (typeof method === 'undefined') {
                resolve({'data': [], total: 0});
                return;
            }
            requests.post(method, params).then((response) => {
                if (response.data == null) {
                    resolve({'data': [], total: 0});
                    return;
                }
                let list = response.data.list.map((value) => this.fromJSON(value));
                resolve({list: list, total: response.data.total});
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    static getOrdered(query_params) {
        let params = {};
        query_params.forEach((item, key) => {
            params[key] = item;
        });

        return new Promise((resolve, reject) => {
            requests.post(this.getMethod("ordered"), params).then((response) => {
                if (response.data == null) {
                    return [];
                }
                let list = response.data.map((value) => this.fromJSON(value));
                resolve(list);
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    /**
     * Fill parameters using for backend pagination
     * @param limit
     * @param page
     * @param orderings
     * @param unique
     * @returns {{}}
     */
    setParams(limit, page, orderings, unique) {
        let params = {};
        if (limit) {
            params.limit = limit;
        }
        if (page) {
            params.page = page;
        }
        if (unique) {
            params.unique = unique;
        }
        if (orderings && orderings.size > 0) {
            params.orderings = {};
            orderings.forEach((item, key) => {
                params.orderings[key] = item;
            });
        }
        return params
    }

    /**
     * Performs a get for given url and parses the response
     * @param url
     * @returns {Promise<unknown>}
     */
    get(url) {
        return new Promise((resolve, reject) => {
            requests.get(url).then((response) => {
                resolve(response.data);
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    /**
     * Gets json and returns a model of given class
     * @param json
     * @returns BaseModel
     */
    static fromJSON(json) {
        return Object.assign(new this.constructor.name(), json);
    }

    /**
     * Gets a list of json data and returns a list of objects for given class
     * @param list
     * @returns {[]|*[]}
     */
    static fromJsonList(list) {
        let result = [];
        if (list === null || typeof list === 'undefined') {
            return [];
        }
        list.forEach((json) => {
            result.push(this.fromJSON(json));
        });
        return result;
    }

    static modelName = "base";
    static modelNamePlural = "bases";

    static withDefaultMethods(methods) {
        if (this.defaultRoute === '') {
            return methods;
        }
        return Object.assign({
            "filtered": "admin/"+ this.defaultRoute +"/search",
            "detailed": this.defaultRoute + "/:id/complete",
            "list": this.defaultRoute + "/",
            "create": this.defaultRoute + "/",
            "one": this.defaultRoute + "/:id",
            "delete": this.defaultRoute + "/:id",
            "update": this.defaultRoute + "/:id",
        }, methods);
    }

    static methods() {
        return this.withDefaultMethods({});
    }

    static getMethod(method) {
        return this.methods()[method];
    }

    static getDetailed(id) {
        return new Promise((resolve, reject) => {
            requests.get(this.getMethod('detailed').replace(':id', id)).then((response) => {
                let obj = this.fromJSON(response.data);
                resolve(obj);
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    static getList(state) {
        if (!(this.modelNamePlural in state)) {
            return [];
        }
        return state[this.modelNamePlural].list
    }

    static getFromId(id, state, returnDefault) {
        if (id == null) {
            if (returnDefault) {
                return {name: "Not set"};
            } else {
                return null;
            }
        }

        if (!(this.modelNamePlural in state)) {
            return new this;
        }

        let item = state[this.modelNamePlural].list.find((e) => e.id === id);
        if (item != null) {
            return item;
        }

        return new this;
    }

    create() {
        return new Promise((resolve, reject) => {
            requests.post(this.getMethod('create'), this).then((response) => {
                this.id = response.data.id;
                resolve();
            }, error => {
                window.console.log(error);
                reject(error);
            });
        });
    }

    deleteThis() {
        if (this.id != null) {
            this.deletedAt = new Date();
            return requests.deleteReq(this.getMethod('delete').replace(':id', this.id));
        }
    }

    update(data) {
        if (!data) {
            data = this;
        }
        return requests.put(this.getMethod('update').replace(':id', this.id), data);
    }

    save() {
        if (this.id !== null && this.id !== "-1" && typeof this.id !== 'undefined') {
            return this.update();
        } else {
            return this.create();
        }
    }

    parseBaseJSON() {
        this.createdAt = new Date(this.createdAt);
        this.updatedAt = new Date(this.updatedAt);
        if (this.deletedAt != null) {
            this.deletedAt = new Date(this.deletedAt);
        } else {
            this.deletedAt = null;
        }
    }

    idRoute() {
        return this.defaultRoute +'/'+ this.id;
    }
}

export const cBase = new BaseModel();