<template>
  <div id="deals-tab" :class="{'minimized': minimized}" v-if="route !== null && route">
    <CafeMap id="cafe-map" ref="cafeMap" @marker-click="selectCafe" :cafes="cafes"
             :center-lat="route.centerLat" :center-lon="route.centerLon"
             :start-lat="route.startLat" :start-lon="route.startLon"
             :way-points="route.wayPoints" :zoom="route.zoom"/>

    <div id="dealList" :class="{'deals': true, 'expanded': expanded}">
      <div class="header">
        <h2>Deals:</h2>
        <i class="fas fa-chevron-up expand" @click="expanded = !expanded"></i>
        <i class="fas fa-times maximize" @click="closeMinimize"></i>
      </div>
      <div class="list">
        <div v-for="cafe in cafes" :key="cafe.id" @click="goToCafe(cafe)"
             :class="{'deal': true, 'activated': cafe.activated}" :id="'cafe-'+ cafe.id">
          <div class="upper">
            <div class="photo">
              <img :src="(cafe.deal.photo != null && cafe.deal.photo !== '')?cafe.deal.photo:cafe.address.photo" alt="Photo of the deal">
              <Ribbon text="Deal" position="top-left" v-if="cafe.deal.oldPrice !== cafe.deal.newPrice" />
            </div>
            <div class="info">
              <span class="cafe">
                {{ cafe.name }}
              </span>
              <span class="cafe-details">
                {{ cafe.address.street }} - {{ prettyDistance(cafe) }} <br />
                {{ getOpenTimes(cafe) }}
              </span>
            </div>
          </div>
          <div class="bottom">
            <span class="name">{{ cafe.deal.text }}</span>
            <div class="price" v-if="cafe.deal.oldPrice !== '' && cafe.deal.newPrice !== ''">
              <span class="old" :class="{'hidden-price': cafe.deal.oldPrice === cafe.deal.newPrice}">€ {{ cafe.deal.oldPrice }}</span><br/>
              <span class="new">€ {{ cafe.deal.newPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#deals-tab {
  position: relative;
  overflow: auto;
  height: 100%;
}

#cafe-map {
  width: 100vw;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  padding: 10px 15px;
  align-items: center;

  h2 {
    margin: 0;
    flex-grow: 10;
  }

  .expand {
    font-size: 16px;
    transition: all 0.5s ease-in-out;
  }
}

.deals {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #2140A1;

  i.maximize {
    display: none;
  }

  .list {
    max-height: calc(var(--vh, 1vh) * 90 - 120vw - 54px);
    min-height: 90px;
    overflow: scroll;
    scroll-behavior: smooth;
    transition: all 0.5s ease-in-out;
  }

  &.expanded {
    .list {
      max-height: 80vh;
    }

    .expand {
      transform: rotate(180deg);
    }
  }
}

#deals-tab.minimized {
  #cafe-map {
    height: calc(100% - 144px);
  }

  .deals {
    .expand {
      display: none;
    }

    .maximize {
      display: inline-block;
    }

    .list {
      max-height: 90px;
    }
  }
}

.deal {
  position: relative;
  width: 100vw;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  color: white;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;

  &:nth-child(2n + 1) {
    background-color: #455FB0;
  }

  &.activated {
    background-color: #FFF5EE;
    color: #2140A1;
    box-shadow: 0 0 5px 6px rgba(0, 0, 0, 0.2);
  }

  .upper {
    display: flex;

    .photo {
      position: relative;
      height: 100px;
      width: 110px;

      img {
        object-fit: cover;
        height: 100px;
        width: 110px;
      }
    }

    .info {
      text-align: left;
      padding: 0 10px;
      flex-grow: 10;
      display: flex;
      flex-direction: column;
    }

    .cafe {
      display: block;
      font-size: 16px;
      font-weight: bold;

      &-details {
        margin-top: 4px;
        font-size: 11px;
      }
    }

    .distance {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 14px;
      padding: 0 5px;
      border-top: 1px solid white;
      border-left: 1px solid white;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    .name {
      font-size: 12px;
    }

    .price {
      height: 100%;
      white-space: nowrap;
      margin-top: -30px;

      .old {
        color: grey;
        font-size: 12px;
        text-decoration: line-through;
      }

      .new {
        font-size: 20px;
        font-weight: bold;
        color: #FF6278;
      }

      .hidden-price {
        color: transparent;
      }
    }
  }
}
</style>

<script>
import CafeMap from "@/components/Map/CafeMap";
import Ribbon from "@/components/Elements/Ribbon";

export default {
  name: "DealTab",
  components: {
    CafeMap,
    Ribbon
  },
  created() {
    if (navigator.geolocation) {
      this.updateLocation();
      setInterval(() => {
        this.updateLocation();
      }, 15000);
      setInterval(() => {
        this.updateOpenTimes();
      }, 60000);
    }
  },
  props: {
    cafes: Array,
    route: Object,
  },
  data() {
    return {
      timer: null,
      expanded: false,
      minimized: false,
    }
  },
  methods: {
    goToCafe(cafe) {
      this.selectCafe(cafe, true);
    },
    selectCafe(cafe) {
      this.$refs.cafeMap.goTo(cafe);
      //Remove activated for current active cafe
      if (this.activeCafe != null) {
        this.$set(this.activeCafe, 'activated', false);
      }

      //Set new activated
      this.activeCafe = cafe;
      this.$set(cafe, 'activated', true);
      this.minimized = true;

      //Scroll the dealList to the cafe
      setTimeout(() => {
        const container = this.$el.querySelector("#dealList .list");
        const target = this.$el.querySelector("#cafe-" + cafe.id);
        container.scrollTop = target.offsetTop - 54;
      }, 150);
      setTimeout(() => {
        const container = this.$el.querySelector("#dealList .list");
        const target = this.$el.querySelector("#cafe-" + cafe.id);
        container.scrollTop = target.offsetTop - 54;
      }, 500);
    },
    recenter() {
      this.$refs.cafeMap.recenter();
    },
    closeMinimize() {
      this.minimized = false;
      this.recenter();
    },
    updateLocation() {
      navigator.geolocation.getCurrentPosition(this.updateNearestDeals);
    },
    updateOpenTimes() {
      this.cafes.forEach((cafe) => {
        cafe.updateOpenStatus();
      });
    },
    updateNearestDeals(position) {
      this.cafes.forEach((cafe) => {
        cafe.distance = this.getDistance(position.coords.latitude, position.coords.longitude, cafe.address.lat, cafe.address.lon)
      });

      this.cafes.sort((a, b) => {
        const scores = {'open': 2, 'almost-closed': 1, 'closed': 0};
        //Open => AlmostClosed => Closed
        if (scores[a.openStatus] !== scores[b.openStatus]) {
          return scores[a.openStatus] - scores[b.openStatus];
        }
        return a.distance - b.distance;
      });
    },
    getDistance(latFrom, lonFrom, latTo, lonTo) {
      if (latFrom === latTo && lonFrom === lonTo) {
        return 0;
      }

      const radLat1 = (Math.PI * latFrom) / 180;
      const radLat2 = (Math.PI * latTo) / 180;

      const theta = lonFrom - lonTo;
      const radTheta = (Math.PI * theta) / 180;

      let dist =
          Math.sin(radLat1) * Math.sin(radLat2) +
          Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);

      if (dist > 1) {
        dist = 1;
      }

      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = dist * 1.609344; //convert miles to km

      return dist;
    },
    prettyDistance(cafe) {
      if (cafe.distance === "-") {
        return "-";
      }
      if (cafe.distance < 1) {
        return Math.round(cafe.distance * 1000) + " m";
      }
      return Math.round(cafe.distance * 10) / 10 + " km";
    },
    getOpenTimes(cafe) {
      const openTimes = cafe.getOpenTimesToday();
      const currentDate = new Date();
      const currentTime = (currentDate.getHours() * 60) + currentDate.getMinutes();
      if (openTimes.length === 0 || currentTime < openTimes[0][0] || currentTime > openTimes[0][1]) return "Closed";
      else if ((openTimes[0][1] - currentTime) < 30) return 'Closes soon: ' + this.toTime(openTimes[0][1]);
      else return 'Open till: ' + this.toTime(openTimes[0][1]);
    },
    toTime(t) {
      return ("0" + Math.floor(t / 60)).slice(-2) + ':' + ("0" + (t % 60)).slice(-2)
    }
  },
}
</script>
