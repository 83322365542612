<template>
  <div id="page-container" :class="{'minimized': minimized}">
    <img alt="Breeze logo" src="@/assets/logo-blue.svg">
    <p>Discount deals at {{ location.name }}</p>
    <div class="divider"></div>
    <div class="deal-list">
      <div v-for="deal in deals" :key="deal.id">
        <div class="deal">
          <div class="deal-photo">
            <img :src="(deal.photo != null && deal.photo !== '')? deal.photo : location.photo" alt="Photo of the deal">
          </div>
          <div class="deal-info">
            <div>
              <span class="deal-name">
                {{ deal.name }}
              </span>
              <span  class="deal-details">
               {{ deal.info }}
              </span>
            </div>
            <span class="deal-price">
              €{{ deal.price }}
            </span>
          </div>
        </div>
        <div class="divider"></div>
      </div>
    </div>
    <div :class="{'overlay': true, 'expanded': expanded}">
      <div class="header">
        <h2 v-if="isTerrace">How Terrace dates work</h2>
        <h2 v-else-if="isWalk">How Walk & Talk dates work</h2>
        <i class="fas fa-chevron-up expand" @click="expanded = !expanded"></i>
        <i class="fas fa-times maximize" @click="minimized = false"></i>
      </div>
      <div class="divider"></div>
      <div class="info">
        <ul class="info-steps">
          <li>
            <span v-if="isTerrace" class="step">
              Mention that you’re on a Breeze date. We’ve made a reservation for you.
            </span>
            <span v-else-if="isWalk" class="step">
              Meet each other in front of <br>
              {{ location.name }}.
            </span>
          </li>
          <li>
            <span v-if="isTerrace" class="step">
              You’ll be guided to your table.
            </span>
            <span v-else-if="isWalk" class="step">
              Show them the deals in de app.
            </span>
          </li>
          <li>
            <span v-if="isTerrace" class="step">
              Claim your discount and enjoy your date!
            </span>
            <span v-else-if="isWalk" class="step">
             Claim your discount and go for a walk!
             <span class="note">(Tip: We haven’t made a reservation, but you can also take a seat if there are spots left.)</span>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@font-face {
  font-family: DM Sans;
  src: url(/src/assets/fonts/DMSans-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

#page-container {
  color: #2140A1;
  background-color: white;
  position: relative;
  overflow: hidden;
  font-family: DM Sans, sans-serif;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 500;
}

.divider {
  border-bottom: 1px solid #BBBBBB;
  width: calc(100% - 20px);
  margin: 0 10px;
}

.container {
  display: none;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}


.header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  padding: 10px 15px;
  align-items: center;

  h2 {
    font-size: 16px;
    margin: 0;
    flex-grow: 10;
    font-weight: 500;
  }

  .expand {
    font-size: 16px;
    transition: all 0.5s ease-in-out;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: #2140A1;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 0;

  i.maximize {
    display: none;
  }

  &.expanded {
    .info {
      max-height: 60vh;
    }

    .expand {
      transform: rotate(180deg);
    }
  }
}

#page-container.minimized {
  .overlay {
    .expand {
      display: none;
    }

    .maximize {
      display: inline-block;
    }
  }
}

.deal {
  position: relative;
  width: 100vw;
  padding: 10px;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  font-size: 14px;

  &-list {
    height: 100%;
    overflow: scroll;
    padding-bottom: 70px;
  }

  &-photo {
    position: relative;
    height: 80px;
    width: 80px;

    img {
      object-fit: cover;
      height: 80px;
      width: 80px;
    }
  }

  &-info {
    text-align: left;
    padding-left: 26px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-details {
    font-weight: normal;
    color: #BBBBBB;
    font-size: 12px;
    display: block;
  }
  &-price {
    color: #FF7153;
  }
}

img {
  width: 70%;
}

.info {
  max-height: 20px;
  overflow: scroll;
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;

  h5 {
    font-size: 16px;
  }
  &-steps {
    list-style: none;
    text-align: left;
    max-width: 260px;
    font-size: 14px;
    padding: 0;
    font-weight: 400;

    .step {
      margin-left: 30px;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 0;

      &:before {
        content: '\25CF';
        font-size: 29px;
      }

      &:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        border-left: 4px solid #2140A1;
        margin-left: 7px;
        height: 100%;
      }

      &:first-of-type:after {
        top: 50%;
      }

      &:last-of-type:after {
        top: -50%;
      }
    }

    .note {
      display: block;
      font-size: 12px;
      color: #BBBBBB;
      font-weight: 400;
    }
  }
}

</style>

<script>
import {requests} from "@/_helpers/requests";

export default {
  name: 'Date',

  data() {
    return {
      location: {},
      deals: [],
      expanded: false,
      minimized: false,
      isTerrace: false,
      isWalk: false,
    }
  },
  async created() {
    let location = await requests.get('locations/'+ this.$route.params.locationId);
    this.location = location.data;

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    if(this.$route.params.type === 'walk') this.isWalk = true
    else if(this.$route.params.type === 'terrace') this.isTerrace = true
    else await this.$router.push({name: 'home'})
  },
  watch: {
    location: function () {
      if(this.location.dealBlob && this.location.dealBlob !== '') {
        this.deals = JSON.parse(atob(this.location.dealBlob));
      }
    }
  }
}
</script>
