<template>
  <div id="date" :class="'active-'+page">
    <div class="container tab-home">
      <HomeTab :has-deals="this.route.hasDeals" />
    </div>
    <div v-if="page === 'deals'" class="container tab-deals">
      <DealsTab :cafes="cafes" :route="route" />
    </div>
    <div class="container tab-questions">
      <QuestionsTab />
    </div>
    <div class="nav">
      <div @click="page = 'home'" :class="{'active': page === 'home'}"><i class="fas fa-home"></i></div>
      <div @click="page = 'deals'" :class="{'active': page === 'deals'}"><i class="fas fa-map-marked-alt"></i></div>
      <div @click="page = 'questions'" :class="{'active': page === 'questions'}"><i class="far fa-question-circle"></i></div>
    </div>
  </div>
</template>

<style lang="scss">
#date {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  display: none;
  width: 100%;
  height: calc(var(--vh, 1vh) * 90);
  overflow: auto;
  box-sizing: border-box;
}

.active-home .tab-home {
  display: block;
}

.active-deals .tab-deals {
  display: block;
}

.active-questions .tab-questions {
  display: block;
}

.nav {
  height: calc(var(--vh, 1vh) * 10);
  width: 100%;
  border-top: 2px solid white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #2140A1;

  div {
    padding: 20px;
    color: white;
    font-size: 30px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    width: 100%;

    &:active, &.active {
      background-color: white;
      color: #2140A1;
    }
  }
}
</style>

<script>

import HomeTab from "@/components/Tabs/HomeTab";
import QuestionsTab from "@/components/Tabs/QuestionsTab";
import DealsTab from "@/components/Tabs/DealsTab";
import {Cafe} from "@/models";
import {routes} from "@/store/routes";

export default {
  name: 'OldDate',
  components: {
    HomeTab,
    DealsTab,
    QuestionsTab,
  },
  data() {
    return {
      page: 'home',
      cityId: null,
      cafes: [],
      route: null,
    }
  },
  created() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    this.cityId = this.$route.params.id;
    const routeName = this.$route.params.route;
    this.routeName = (routeName && routeName in routes[this.cityId])?routeName:'default';
    this.loadCafes();

    this.route = routes[this.cityId][this.routeName];
    this.route.startLat = (this.$route.params.startLat)?parseFloat(this.$route.params.startLat.replace('-', '.')):this.route.wayPoints[0].lat;
    this.route.startLon = (this.$route.params.startLon)?parseFloat(this.$route.params.startLon.replace('-', '.')):this.route.wayPoints[0].lng;
   },
  methods: {
    loadCafes() {
      Cafe.loadForCityID(this.cityId).then((cafes) => {
        this.cafes = cafes;
      });
    },
  }
}
</script>
