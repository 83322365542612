<template>
  <div id="deals-tab" :class="{'minimized': minimized}">
    <div id="home-tab">
      <img alt="Breeze logo" src="@/assets/logo-blue.svg">
      <div class="info">
        <h5>What to do during your Breeze date?</h5>
        <ul class="info-steps">
          <li>
            <span v-if="isTerraceDate" class="step">
              Mention that you’re on a Breeze date and you’ll be guided to your table.
            </span>
            <span v-else class="step">Claim your to-go deal at <br>
              {{ location.name }}
            </span>
          </li>
          <li>
            <span v-if="isTerraceDate" class="step">
              Claim your Breeze deal.
            </span>
            <span v-else class="step">Use the map to find fun highlights, places to sit or public toilets.
              <span class="note">(You’ll get a printed one at your meetingpoint)</span>
            </span>
          </li>
          <li>
            <span v-if="isTerraceDate" class="step">
              Enjoy your date!
            </span>
            <span v-else class="step">
              Use the ice-breakers to get to know each other.
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="deals.length" id="dealList" :class="{'deals': true, 'expanded': expanded}">
      <div class="header">
        <h2>Deals at {{ location.name }}:</h2>
        <i class="fas fa-chevron-up expand" @click="expanded = !expanded"></i>
        <i class="fas fa-times maximize" @click="minimized = false"></i>
      </div>
      <div class="divider"></div>
      <div class="list" :class="{'terrace-list': isTerraceDate}">
        <div v-for="deal in deals" :key="deal.id">
          <div class="deal">
            <div class="deal-photo">
              <img :src="(deal.photo != null && deal.photo !== '')? deal.photo : location.photo" alt="Photo of the deal">
            </div>
            <div class="deal-info">
              <div>
                <span class="deal-name">
                  {{ deal.name }}
                </span>
                <span  class="deal-details">
                 {{ deal.info }}
                </span>
              </div>
              <span class="deal-price">
                €{{ deal.price }}
              </span>
            </div>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#deals-tab {
  color: #2140A1;
  background-color: white;
  position: relative;
  overflow: auto;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid white;
  padding: 10px 15px;
  align-items: center;

  h2 {
    font-size: 16px;
    margin: 0;
    flex-grow: 10;
  }

  .expand {
    font-size: 16px;
    transition: all 0.5s ease-in-out;
  }
}

.deals {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: #2140A1;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px 0;

  i.maximize {
    display: none;
  }

  .list {
    max-height: 20px;
    overflow: scroll;
    scroll-behavior: smooth;
    transition: all 0.5s ease-in-out;

    &.terrace-list {
      max-height: 50px;
    }
  }

  &.expanded {
    .list {
      max-height: 60vh;
    }

    .expand {
      transform: rotate(180deg);
    }
  }
}

#deals-tab.minimized {
  .deals {
    .expand {
      display: none;
    }

    .maximize {
      display: inline-block;
    }

    .list {
      max-height: 90px;
    }
  }
}

.deal {
  position: relative;
  width: 100vw;
  padding: 10px;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  font-size: 14px;

  &-photo {
    position: relative;
    height: 80px;
    width: 80px;

    img {
      object-fit: cover;
      height: 80px;
      width: 80px;
    }
  }

  &-info {
    text-align: left;
    padding-left: 26px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-details {
    font-weight: normal;
    color: #BBBBBB;
    font-size: 12px;
    display: block;
  }
  &-price {
    color: #FF6278;
  }
}

#home-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

img {
  width: 70%;
}

.info {
  h5 {
    font-size: 16px;
  }
  &-steps {
    list-style: none;
    text-align: left;
    max-width: 260px;
    font-size: 14px;
    padding: 0;

    .step {
      margin-left: 30px;
    }

    li {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 0;

      &:before {
        content: '\25CF';
        font-size: 29px;
      }

      &:after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        border-left: 4px solid #2140A1;
        margin-left: 7px;
        height: 100%;
      }

      &:first-of-type:after {
        top: 50%;
      }

      &:last-of-type:after {
        top: -50%;
      }
    }

    .note {
      display: block;
      font-size: 12px;
      color: #BBBBBB;
    }
  }
}
</style>

<script>
export default {
  name: "TestHomeTab",
  props: {
    location: Object,
    isTerraceDate: Boolean
  },
  data() {
    return {
      deals: [],
      expanded: false,
      minimized: false,
    }
  },
  created() {
  },
  watch: {
    location: function () {
      if(this.location.dealBlob && this.location.dealBlob !== '') {
        this.deals = JSON.parse(atob(this.location.dealBlob));
      }
    }
  }
}
</script>
