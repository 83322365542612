<template>
<div id="questions-tab">
  <h1>Some icebreaker questions</h1>
  Find out more about your date! Consider asking one or more of the
  icebreaker questions below. Enjoy your date!

  <h2>Questions</h2>
  <ol class="questions">
    <li>What is the last concert you went to?</li>
    <li>What is the first thing you will do when the lockdown is over?</li>
    <li>Beer or wine?</li>
    <li>If you had a time machine, would you go to the past or the future?</li>
    <li>If you would be famous, what would you be famous for?</li>
    <li>What are you most proud of?</li>
    <li>What is a fact about you that nobody else would guess?</li>
    <li>If you could hop on the plane right now, where would you go?</li>
    <li>What’s the funniest thing you did as a kid that your parents still talk about to this day?</li>
    <li>What was the most embarrassing thing you have done while on a date?</li>
  </ol>
</div>
</template>

<style lang="scss" scoped>
#questions-tab {
  padding: 10px;
  box-sizing: border-box;
}

  ol.questions {
    text-align: left;

    > li {
      margin-bottom: 10px;
    }
  }
</style>

<script>
export default {
name: "QuestionsTab"
}
</script>