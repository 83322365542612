<template>
  <iframe width="100%" height="100%" src="https://breeze.social/share/123"></iframe>
</template>

<script>
export default {
name: "Home"
}
</script>

<style lang="scss" scoped>
  iframe {
    height: 100vh;
    width: 100vw;
  }
</style>