<template>
  <div id="questions-tab">
    <div class="content">
      <h2>Ice-breakers</h2>
      Find out more about your date! Consider using one or more of the ice breakers below.

      <h2>Questions</h2>
      <ol class="questions">
        <li>What is the last concert you went to?</li>
        <li>What is the first thing you will do when the lockdown is over?</li>
        <li>What was something that you thought was interesting about the profile of your match?</li>
        <li>If you had a time machine, would you go to the past or the future?</li>
        <li>If you would be famous, what would you be famous for?</li>
        <li>What are you most proud of?</li>
        <li>What is a fact about you that nobody else would guess?</li>
        <li>If you could hop on the plane right now, where would you go?</li>
        <li>What’s the funniest thing you did as a kid that your parents still talk about to this day?</li>
        <li>What was the most embarrassing thing you have done while on a date?</li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestQuestionsTab"
}
</script>

<style scoped lang="scss">
#questions-tab {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #2140A1;
  background-color: white;
  overflow: auto;

  .content {
    max-width: 320px;
    margin: auto;
    h2 {
      font-size: 16px;
    }
  }
}

ol.questions {
  text-align: left;
  padding-left: 30px;

  > li {
    margin-bottom: 10px;
  }
}

</style>
