export const routes = {
    //Amsterdam
    '01877c2d-1379-40f9-bbd9-bb365ff872b1': {
        'default': {
            centerLat: 52.3362429,
            centerLon: 4.8694444,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 52.363971463510026, lng: 4.882303887270557 },
                { lat: 52.36611866260788, lng: 4.886666500222855 },
                { lat: 52.368860095399, lng: 4.8848345649657245 },
                { lat: 52.368976371353526, lng: 4.8824125302339985 },
                { lat: 52.38047717045618, lng: 4.888051874675299 },
                { lat: 52.38070720500753, lng: 4.887621380128916 },
                { lat: 52.37965484852879, lng: 4.880567840972209 },
                { lat: 52.37247510623286, lng: 4.876464570723628 },
                { lat: 52.37234164508571, lng: 4.875942881071294 },
                { lat: 52.363971534786145, lng: 4.882296447896343 },
            ]
        }
    },
    //Delft
    'fec769a0-bcac-43e4-b0ea-63e3aaf31c26': {
        'default': {
            centerLat: 52.0111076,
            centerLon: 4.3566778,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 52.00980121796786, lng: 4.355793099270927 },
                { lat: 52.00951725003055, lng: 4.355738113989296 },
                { lat: 52.01006207065194, lng: 4.3570523963695775 },
                { lat: 52.01209284207816, lng: 4.355335228028502 },
                { lat: 52.012645885677635, lng: 4.356880180414382 },
                { lat: 52.01163554415499, lng: 4.35762583451876 },
                { lat: 52.011726343753814, lng: 4.357883326587103 },
                { lat: 52.01151337713226, lng: 4.358038894708795 },
                { lat: 52.01170488204647, lng: 4.3585753365110635 },
                { lat: 52.011335078711795, lng: 4.358886472756102 },
                { lat: 52.01391208216319, lng: 4.365119926509817 },
                { lat: 52.01825847876455, lng: 4.359299532959037 },
                { lat: 52.018000975204124, lng: 4.3587362690701585 },
                { lat: 52.016571473330686, lng: 4.360753290237499 },
                { lat: 52.014970244944955, lng: 4.357148401328692 },
                { lat: 52.01342838942761, lng: 4.359058134140578 },
                { lat: 52.012668997808866, lng: 4.356885544843534 },
                { lat: 52.013045393524024, lng: 4.35659050185149 },
                { lat: 52.01207303143981, lng: 4.353218965123051 },
                { lat: 52.00931926659819, lng: 4.355962864944307 },
                { lat: 52.0091492144111, lng: 4.355488113949886 },
                { lat: 52.00824611091114, lng: 4.356236450263724 },
            ]
        }
    },
    //Eindhoven
    'b2ec3b58-8943-4635-92a9-00369f127177': {
        'default': {
            centerLat: 51.4484803,
            centerLon: 5.4164911,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 51.44163214735871, lng: 5.479748905629173 },
                { lat: 51.44086605575011, lng: 5.480160501584535 },
                { lat: 51.441623411075994, lng: 5.48727640220695 },
                { lat: 51.436212976852325, lng: 5.484910693828685 },
                { lat: 51.43505924760046, lng: 5.48341133897561 },
                { lat: 51.43740680514157, lng: 5.479664292972511 },
                { lat: 51.4362347135019, lng: 5.477408555223222 },
                { lat: 51.43587522145401, lng: 5.47774919577508 },
                { lat: 51.43611432578656, lng: 5.477097418974428 },
                { lat: 51.43724127619826, lng: 5.475469318080182 },
                { lat: 51.43665272396712, lng: 5.474948969534355 },
                { lat: 51.438037147277875, lng: 5.471931484381734 },
                { lat: 51.438417062996464, lng: 5.472233355936085 },
                { lat: 51.43921124164815, lng: 5.475848973711485 },
                { lat: 51.44108210477033, lng: 5.476135970067161 },
                { lat: 51.44113058903959, lng: 5.47682529776857 },
                { lat: 51.44139474377157, lng: 5.476763606959091 },
                { lat: 51.4416304755048, lng: 5.478190542162167 },
                { lat: 51.44152849242532, lng: 5.4783085593382905 },
                { lat: 51.44163214735871, lng: 5.479748905629173 },
            ]
        }
    },
    //Leiden
    '74a47308-d46a-4b2f-b763-a8a4660771fd': {
        'default': {
            centerLat: 52.1517316,
            centerLon: 4.4466505,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 52.1640084264028, lng: 4.484297017211843 },
                { lat: 52.163869432335716, lng: 4.484326047187553 },
                { lat: 52.164114267936945, lng: 4.486261816166848 },
                { lat: 52.162803789567306, lng: 4.486031146184226 },
                { lat: 52.162793917644166, lng: 4.487934173484519 },
                { lat: 52.162488709602435, lng: 4.4879301501683475 },
                { lat: 52.162392816902575, lng: 4.49016279940868 },
                { lat: 52.16027556359542, lng: 4.491025556148956 },
                { lat: 52.16021427188427, lng: 4.491601560552618 },
                { lat: 52.159528724365885, lng: 4.4913364338360795 },
                { lat: 52.15965813947319, lng: 4.49415354382019 },
                { lat: 52.15802441316422, lng: 4.493417744243431 },
                { lat: 52.158539862132436, lng: 4.492886666863639 },
                { lat: 52.158702352787145, lng: 4.493193109243706 },
                { lat: 52.1585390243684, lng: 4.492897681044607 },
                { lat: 52.1583596669035, lng: 4.493059284140843 },
                { lat: 52.15806897197388, lng: 4.492170719746379 },
                { lat: 52.156902047971805, lng: 4.4938160790728565 },
                { lat: 52.156010979932674, lng: 4.492102147497664 },
                { lat: 52.1569818565226, lng: 4.4860638244641144 },
                { lat: 52.16280420337468, lng: 4.486028955724487 },
                { lat: 52.16283628711608, lng: 4.484576539531173 },
                { lat: 52.16400609366538, lng: 4.484290884260357 },
            ]
        }
    },
    //Rotterdam
    '23936737-6314-4c78-87c6-02598b733af1': {
        'default': {
            centerLat: 51.9173703,
            centerLon: 4.4797731,
            zoom: 13.6,
            wayPoints: [
                { lat: 51.9198916884062, lng: 4.4882935883459005 },
                { lat: 51.91971137078332, lng: 4.49241614914178 },
                { lat: 51.91867908012338, lng: 4.493855154276874 },
                { lat: 51.91485400024522, lng: 4.48755905140584 },
                { lat: 51.91838499588357, lng: 4.484646461207313 },
                { lat: 51.91740752062945, lng: 4.479805576083349 },
                { lat: 51.91630238264064, lng: 4.479931639912717 },
                { lat: 51.91509652321183, lng: 4.475101509770417 },
                { lat: 51.917219884534695, lng: 4.47356613045002 },
                { lat: 51.9184953970579, lng: 4.479681567007405 },
                { lat: 51.9196597680992, lng: 4.479487819106702 },
                { lat: 51.91968458251447, lng: 4.47979359094499 },
                { lat: 51.92002123006541, lng: 4.479654116062465 },
                { lat: 51.9203012147579, lng: 4.482026863855744 },
                { lat: 51.920193687002886, lng: 4.4820778258287906 },
                { lat: 51.921092537656605, lng: 4.4870611906801505 },
                { lat: 51.91964670683197, lng: 4.488185036239368 },
            ]
        },
        'stadswandeling': {
            centerLat: 51.9173703,
            centerLon: 4.4797731,
            zoom: 13.6,
            wayPoints: [
                { lat: 51.91705430372423, lng: 4.47337427962176 },
                { lat: 51.91790451309862, lng: 4.476652022997501 },
                { lat: 51.91596225302276, lng: 4.4784893361734275 },
                { lat: 51.916359314722456, lng: 4.482505609739595 },
                { lat: 51.917363557879554, lng: 4.485124615095968 },
                { lat: 51.91469751014889, lng: 4.487696107783397 },
                { lat: 51.912451507875, lng: 4.483016494586259 },
                { lat: 51.90956097736523, lng: 4.482648033735444 },
                { lat: 51.90767815697132, lng: 4.480246734115085 },
                { lat: 51.90652714019021, lng: 4.478459148425964 },
                { lat: 51.904808539029474, lng: 4.474592097400269 },
                { lat: 51.90507496780767, lng: 4.47336364566825 },
                { lat: 51.90697800612721, lng: 4.46969039653284 },
                { lat: 51.90865679058259, lng: 4.4683998027872835 },
                { lat: 51.90945643230515, lng: 4.471174145001149 },
                { lat: 51.90974103477954, lng: 4.471573794142629 },
                { lat: 51.90998592383958, lng: 4.471394086141928 },
                { lat: 51.910461564022405, lng: 4.472846105339555 },
                { lat: 51.912568134389026, lng: 4.472462410337682 },
                { lat: 51.91278818982024, lng: 4.4728110975078375 },
                { lat: 51.91435998295186, lng: 4.471952790623945 },
                { lat: 51.91578943965176, lng: 4.472022528057421 },
                { lat: 51.914226758139115, lng: 4.469102081105811 },
                { lat: 51.914797555297305, lng: 4.46589415912985 },
                { lat: 51.91705733201082, lng: 4.473379300860251 },
            ]
        }
    },
    //The Hague
    '192d792b-8b7f-4fd5-b07b-c756f8745b98': {
        'default': {
            centerLat: 52.0716335,
            centerLon: 4.2398292,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 52.07931176370717, lng: 4.316292897986413 },
                { lat: 52.08136562958723, lng: 4.313782350401751 },
                { lat: 52.08002386916502, lng: 4.3099736136641065 },
                { lat: 52.07924582745775, lng: 4.31073536098035 },
                { lat: 52.07891284782203, lng: 4.309791223405871 },
                { lat: 52.07839194402771, lng: 4.310241834494353 },
                { lat: 52.07807214562982, lng: 4.3091904085567965 },
                { lat: 52.0764137729441, lng: 4.311636583120143 },
                { lat: 52.075576320769706, lng: 4.309560553425943 },
                { lat: 52.077168785233376, lng: 4.307661549452074 },
                { lat: 52.078075442530285, lng: 4.309222595043915 },
                { lat: 52.078164458803904, lng: 4.307978050076185 },
                { lat: 52.07985243741402, lng: 4.306497470699967 },
                { lat: 52.07931176372366, lng: 4.304882780845125 },
                { lat: 52.08259856307785, lng: 4.301503197530659 },
                { lat: 52.083752346992, lng: 4.304169313252124 },
                { lat: 52.08066549609063, lng: 4.307527439435601 },
                { lat: 52.083190705193154, lng: 4.313530223257087 },
                { lat: 52.08574873470693, lng: 4.316072957403828 },
                { lat: 52.081997347430416, lng: 4.321759240395212 },
                { lat: 52.0794654772555, lng: 4.316276805298252 },
            ]
        }
    },
    //Utrecht
    '069dcf12-ecf4-46ee-93ff-8cd746253271': {
        'default': {
            centerLat: 52.084166,
            centerLon: 5.0124523,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 52.09287155835708, lng: 5.118737516921226 },
                { lat: 52.09085154911826, lng: 5.116582646757636 },
                { lat: 52.09074443034053, lng: 5.117038622289399 },
                { lat: 52.08962547318113, lng: 5.117712854273856 },
                { lat: 52.09074098704629, lng: 5.121616502703744 },
                { lat: 52.090044306376555, lng: 5.121920234216269 },
                { lat: 52.08975095980944, lng: 5.121142393607817 },
                { lat: 52.08159894650939, lng: 5.124085235406989 },
                { lat: 52.08461217879988, lng: 5.127963615737202 },
                { lat: 52.08469294099875, lng: 5.1278509629594256 },
                { lat: 52.084808161236005, lng: 5.1280852964854375 },
                { lat: 52.08518394980134, lng: 5.12943981202928 },
                { lat: 52.08875336928527, lng: 5.128516640587986 },
                { lat: 52.08926017207784, lng: 5.129157236499673 },
                { lat: 52.09286170369885, lng: 5.1267737208434925 },
                { lat: 52.09336118980835, lng: 5.121086693948896 },
                { lat: 52.09286677238524, lng: 5.11880686532717 },
            ]
        }
    },
    //Den Bosch
    'f29372c9-c839-4196-916c-5917938dc606': {
        'default': {
            centerLat: 51.688285,
            centerLon: 5.304341,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 51.689403686895915, lng: 5.303341904835101 },
                { lat: 51.688687029007234, lng: 5.303771058280117 },
                { lat: 51.688853307947646, lng: 5.304286042407091 },
                { lat: 51.687686016862635, lng: 5.3085185682308325 },
                { lat: 51.685591924129035, lng: 5.308430719544108 },
                { lat: 51.68792988996387, lng: 5.304159038314561 },
                { lat: 51.68753912550948, lng: 5.302512832531299 },
                { lat: 51.68973235128395, lng: 5.300562866578851 },
                { lat: 51.68951286721607, lng: 5.2990876516222345 },
                { lat: 51.69034361876404, lng: 5.299212379480769 },
                { lat: 51.690467291566165, lng: 5.301523990627384 },
                { lat: 51.68942036703793, lng: 5.302519710893388 },
                { lat: 51.689403686895915, lng: 5.303341904835101 },
            ]
        }
    },
    //Breda
    '4862941f-68f3-4366-94f2-bfb47c8c9120': {
        'default': {
            centerLat: 51.587238,
            centerLon: 4.777322,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: [
                { lat: 51.589387170732984, lng: 4.775081404526879 },
                { lat: 51.58982155400022, lng: 4.773237427165049 },
                { lat: 51.58828842029137, lng: 4.771671017100664 },
                { lat: 51.58814843593538, lng: 4.772947748531042 },
                { lat: 51.58689522348519, lng: 4.774658997876344 },
                { lat: 51.58449535847557, lng: 4.774540980703231 },
                { lat: 51.583175378683876, lng: 4.776123484043389 },
                { lat: 51.581051997430144, lng: 4.7775986989436561 },
                { lat: 51.58226870369346, lng: 4.780908544812583 },
                { lat: 51.582898711402535, lng: 4.779545982675458 },
                { lat: 51.58356204341308, lng: 4.780318458850991 },
                { lat: 51.58561864440206, lng: 4.780828078567227 },
                { lat: 51.58580196737587, lng: 4.7801199754213695 },
                { lat: 51.58643192609168, lng: 4.780361374229611 },
                { lat: 51.58703854475102, lng: 4.779642542245835 },
                { lat: 51.5874185105568, lng: 4.780141433095346 },
                { lat: 51.58837007763562, lng: 4.778466393583278 },
                { lat: 51.5894457815641, lng: 4.779860132863836 },
                { lat: 51.59168904676513, lng: 4.785349807940121 },
                { lat: 51.59236724739791, lng: 4.781377456461085 },
                { lat: 51.5922022806034, lng: 4.779985389974457 },
                { lat: 51.590844197901696, lng: 4.7784994461946075 },
                { lat: 51.59016597453044, lng: 4.778099797037297 },
                { lat: 51.58967938051737, lng: 4.778134665740707 },
                { lat: 51.589387170732984, lng: 4.775081404526879 },
            ]
        }
    },
    //Nijmegen
    'fa3f18d4-ae5f-42c7-b135-69a89d211ddb': {
        'default': {
            centerLat: 51.84406049999999,
            centerLon: 5.8678846,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: []
        }
    },
    //Alkmaar
    '1bbb8cf3-b8a7-4482-bccb-b29ff81c117b': {
        'default': {
            centerLat: 52.63154118870765,
            centerLon: 4.74788824526856,
            zoom: 13.6,
            hasDeals: false,
            wayPoints: []
        }
    },
};


/**
 * ([0-9.]*), ([0-9.]+)
 * {lat: $1, lng: $2},
 */
